import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"

const BlogPost = ({ data }) => {
  const { date, title } = data.mdx.frontmatter
  const { body, slug } = data.mdx

  return (
    <div className="blog-post-container">
      <div key={slug} className="blog-post-content">
        <h3>{title}</h3>
        <small>{date}</small>
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query BlogQuery($slug: String, $header: String) {
    mdx(frontmatter: { header: { eq: $header } }, slug: { eq: $slug }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
      slug
      body
    }
  }
`

export default BlogPost
